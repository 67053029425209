<template>
  <AppPage>
    <AppHeader>
      <template #left>
        <NuxtLink to="/">
          <AppLogoText class="h-6" />
        </NuxtLink>
      </template>
      <template #right>
        <AppColorModeToggle />
      </template>
    </AppHeader>

    <slot />

  </AppPage>
</template>